<template>
  <div id='news'>
    <Header :currentIndex="params.id"/>
    <h1 class="news_title">News</h1>
    <div class="line"></div>
    <div v-if="dataInfo.length" class="news_main">
      <div v-for="item in dataInfo" :key="item.id" @click="getNewsInfo(item.id)" class="news_main_item">
        <div class="news_main_img">
          <img :src="item.icon" alt="">
        </div>
        <div class="news_main_content">
          <p class="news_main_title">{{item.title}}</p>
          <div class="news_main_info">
            <span class="news_main_cmp">美翻网</span>
            <span class="news_main_time">{{item.create_datetime}}</span>
          </div>
        </div>
        <div class="news_main_go"><Icon type="ios-arrow-round-forward" size="36" /></div>
      </div>
      <Page @on-change="getPage" :total="count" :page-size="params.page_size"  class="page"/>
    </div>
    <div v-else class="no_data"></div>
    <div class="bg_left"><img src="@assets/image/bg_left.png" alt=""></div>
    <div class="bg_right"><img src="@assets/image/bg_right.png" alt=""></div>
    <Footer :currentIndex="params.id"/>
  </div>
</template>

<script>
import Header from '@cpt/Header'
import Footer from '@cpt/Footer'
import {getContent} from '@api'

export default {
  data () {
    return {
      dataInfo: [],
      count: 0,
      params: {
        id: 4,
        page: 1,
        page_size: 10
      }
    }
  },
  components: {
    Header,
    Footer
  },
  created() {
    this.getData(this.params)
  },
  methods:{
    getPage(e) {
      this.params.page = e
      this.getData(this.params)
    },
    getNewsInfo(id) {
      this.$router.push({name: "NewsInfo", params: {id}})
    },
    getData(params) {
      getContent(params).then(res => {
        this.count = res.data.count
        this.dataInfo = res.data.data
      })
    }
  }
}
</script>

<style lang='less' scoped>
#news {
  position: relative;
  overflow: hidden;
  .news_title {
    width: 1200px;
    margin: 178px auto 0;
    font-size: 49px;
    font-weight: bold;
    animation: move 1s;
  }
  @keyframes move {
    0% {
      transform: translateX(-500px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  .line {
    position: absolute;
    top: 305px;
    right: 0;
    width: 60%;
    height: 1px;
    background: #e5e5e5;
    z-index: -1;
  }
  .news_main {
    width: 1200px;
    margin: 72px auto 32px;
    .news_main_item {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: 195px;
      border-bottom: 1px solid #e5e5e5;
      cursor: pointer;
      .news_main_img {
        width: 250px;
        height: 130px;
        overflow: hidden;
        img {
          width: 250px;
          height: 130px;
          transition: 1s;
        }
      }
      .news_main_img:hover img{
        transform: scale(1.2);
      }
      .news_main_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 830px;
        height: 130px;
        padding-left: 30px;
        padding-right: 120px;
        .news_main_title {
          margin-top: 12px;
          font-size: 20px;
          font-weight: 600;
        }
        .news_main_text {
          margin-top: 8px;
          font-size: 20px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }
        .news_main_info {
          margin-bottom: 12px;
          color: #666;
          font-size: 14px;
          .news_main_cmp {
            padding-right: 16px;
            border-right: 1px solid #E5E5E5;
          }
          .news_main_time {
            padding-left: 16px;
          }
        }
      }
      .news_main_go {
        width: 40px;
        height: 40px;
        border: 2px solid #666;
        border-radius: 50%;
        transition: .3s;
      }
    }
    .news_main_item:hover .news_main_title {
      color: #2299E1;
    }
    .news_main_item:hover .news_main_go {
      color: #2299E1;
      border-color: #2299E1;
      transform: scale(1.2);
    }
  }
  .bg_left {
    position: absolute;
    top: 90px;
    left: 0;
    z-index: -1;
  }
  .bg_right {
    position: absolute;
    top: 875px;
    right: 0;
    z-index: -1;
  }
  .no_data {
    width: 100%;
    height: 143px;
  }
  .page {
    margin-top: 32px;
    text-align: center;
  }
}
</style>